import React, { FC, useState, useEffect } from 'react'
import { ScrollSync } from 'react-scroll-sync'
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { useStopwatch } from 'react-timer-hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import { cloneDeep, findIndex } from 'lodash'
import { TaskArtefact } from './task-artefact'

import { PlanTask } from '../../types'
import { Link } from 'react-router-dom'

export interface PairwiseTaskProps {
  task: PlanTask
  updatePending: boolean
  onChoose: (updatedTask: PlanTask) => void
}
export const PairwiseTask: FC<PairwiseTaskProps> = ({
  task,
  updatePending,
  onChoose
}): JSX.Element => {
  const [scrollTogether, setScrollTogether] = useState(false)
  const [expandedArtefact, setExpandedArtefact] = useState<string | undefined>(
    undefined
  )
  const [artefactLoadedCount, setArtefactLoadedCount] = useState(0)
  const { seconds, minutes, hours, days, start, pause, reset } = useStopwatch({
    autoStart: false
  })
  const [showCommentArea, setShowCommentArea] = useState(false)
  const [comments, setComments] = useState('')
  const maxLength = 500
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    reset()
    setArtefactLoadedCount(0)
  }, [task])
  useEffect(() => {
    if (artefactLoadedCount === 2) {
      start()
    }
  }, [artefactLoadedCount])

  const choose = (chosenKey: string) => {
    pause()
    const timeOnTask = days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
    const cloned = cloneDeep(task)
    const winnerIndex = findIndex(cloned.artefacts, {
      artefactKey: chosenKey
    })
    cloned.artefacts[winnerIndex].rankOrder = 1
    const loserIndex = !winnerIndex ? 1 : 0
    cloned.artefacts[winnerIndex].timeOnArtefact = timeOnTask / 2
    cloned.artefacts[loserIndex].rankOrder = 2
    cloned.artefacts[loserIndex].timeOnArtefact = timeOnTask / 2

    cloned.taskTime = timeOnTask
    if (comments !== '' && comments.length > 0) {
      cloned.comments = comments
    }
    // now put the result
    cloned.artefacts[0].initialOrder = 'A'
    cloned.artefacts[1].initialOrder = 'B'
    onChoose(cloned)
  }

  let bottomClassName = showCommentArea
    ? 'mx-0 flex-fill mw-100'
    : 'mx-0 flex-fill mw-100 mb-45'
  return (
    <ScrollSync enabled={scrollTogether}>
      <>
        <div className="bigc d-flex flex-fill overflow-hidden">
          <Row className="mx-0 flex-fill  mw-100">
            <Col
              className={`${
                expandedArtefact &&
                expandedArtefact !== task.artefacts[0].artefactKey
                  ? 'invisible col-0'
                  : ''
              }  ${
                !expandedArtefact ? 'col-pairwise' : 'pr-0'
              } pl-0 h-100 d-flex flex-column`}
            >
              <TaskArtefact
                scrollSynced={scrollTogether}
                retrievePending={false}
                choosePending={updatePending}
                isExpanded={expandedArtefact === task.artefacts[0].artefactKey}
                onExpandChange={newVal => {
                  if (newVal) {
                    setExpandedArtefact(task.artefacts[0].artefactKey)
                  } else {
                    setExpandedArtefact(undefined)
                  }
                }}
                artefactKey={task.artefacts[0].artefactKey}
                onChoose={() => choose(task.artefacts[0].artefactKey)}
                onLoaded={() => {
                  setArtefactLoadedCount(artefactLoadedCount + 1)
                }}
              />
            </Col>

            <Col
              xs="auto"
              className={`${
                expandedArtefact !== undefined ? 'invisible col-0 p-0' : ''
              } text-center pt-3`}
            >
              <Button
                id="ScrollTogether"
                className="px-35"
                color={scrollTogether ? 'primary' : 'white'}
                onClick={() => setScrollTogether(!scrollTogether)}
              >
                <FontAwesomeIcon className="font-normal" icon={faLink} />
              </Button>
              <UncontrolledTooltip placement="bottom" target="ScrollTogether">
                Scroll together
              </UncontrolledTooltip>
            </Col>

            <Col
              className={`${
                expandedArtefact &&
                expandedArtefact !== task.artefacts[1].artefactKey
                  ? 'invisible col-0'
                  : ''
              }  ${
                !expandedArtefact ? 'col-pairwise' : 'pl-0'
              } pr-0 h-100 d-flex flex-column`}
            >
              <TaskArtefact
                scrollSynced={scrollTogether}
                artefactKey={task.artefacts[1].artefactKey}
                retrievePending={false}
                choosePending={updatePending}
                isExpanded={expandedArtefact === task.artefacts[1].artefactKey}
                onExpandChange={newVal => {
                  if (newVal) {
                    setExpandedArtefact(task.artefacts[1].artefactKey)
                  } else {
                    setExpandedArtefact(undefined)
                  }
                }}
                onChoose={() => choose(task.artefacts[1].artefactKey)}
                onLoaded={() => {
                  setArtefactLoadedCount(artefactLoadedCount + 1)
                }}
              />
            </Col>
          </Row>
        </div>
        {!expandedArtefact && (
          <div>
            <Row className={`${bottomClassName}`}>
              {!showCommentArea && (
                <Col lg="24" xl="24" className="text-center pt-1">
                  Do you wish to add comment?{' '}
                  <Link
                    className="font-weight-bold"
                    onClick={e => {
                      e.preventDefault()
                      setShowCommentArea(true)
                    }}
                    to={''}
                  >
                    Click here to add
                  </Link>
                </Col>
              )}
              {showCommentArea && (
                <textarea
                  spellCheck="true"
                  maxLength={500}
                  value={comments}
                  onChange={e => {
                    setComments(e.target.value)
                    if (
                      e.target.value != undefined &&
                      e.target.value != null &&
                      e.target.value != ''
                    ) {
                      setCharCount(e.target.value.length)
                    }
                  }}
                  style={{
                    width: '90%',
                    height: '40px',
                    fontSize: '12px',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    margin: '1% 5% 1% 5%'
                  }}
                  placeholder="Add a comment(optional)"
                />
              )}
              {showCommentArea && (
                <div className="text-right pt-1 col-lg-23 col-xl-23 flex-fill">
                  <span>
                    {charCount}/{maxLength} characters
                  </span>
                </div>
              )}
            </Row>
          </div>
        )}
      </>
    </ScrollSync>
  )
}
